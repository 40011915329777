import { Component } from '@angular/core';
import { INavElement } from '@shared/interfaces/navigation.interface';
import { RoutingEnum } from '@shared/enums/navigation.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  currentYear = new Date().getUTCFullYear();

  aboutElementsList: INavElement[] = [
    {
      text: 'A better future',
      isExternal: true,
      linkTree: [
        RoutingEnum.ABetterFuture
      ]
    },
    {
      text: 'Partner with us',
      isExternal: true,
      linkTree: [
        RoutingEnum.PartnerWithUs
      ]
    },
    {
      text: 'Work at Seatfrog',
      isExternal: true,
      linkTree: [
        RoutingEnum.WorkAtSeatfrog
      ]
    },
  ];
  howItWorksElementsList: INavElement[] = [
    {
      text: 'Buying tickets',
      isExternal: true,
      linkTree: [
        RoutingEnum.SeatfrogHome
      ]
    },
    {
      text: 'Train Swap',
      isExternal: true,
      linkTree: [
        RoutingEnum.TrainSwap
      ]
    },
    {
      text: 'Help Centre',
      isExternal: true,
      linkTree: [
        RoutingEnum.HelpCentre
      ]
    },
    {
      text: 'Terms & Conditions',
      linkTree: [
        `/${RoutingEnum.TermsAndConditions}`,
      ]
    },
  ];
  connectElementsList: INavElement[] = [
    {
      text: 'Facebook',
      isExternal: true,
      linkTree: [
        RoutingEnum.Facebook
      ]
    },
    {
      text: 'Instagram',
      isExternal: true,
      linkTree: [
        RoutingEnum.Instagram
      ]
    },
    {
      text: 'Twitter',
      isExternal: true,
      linkTree: [
        RoutingEnum.Twitter
      ]
    },
  ];
}
