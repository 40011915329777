<footer>
    <div class="footer-component wrapper">
        <div class="footer-component__top-section">
            <div class="footer-component__logo-section">
                <!-- <img class="footer-component__logo-section--seatfrog" src="assets/images/Seatfrog-logo.svg" alt="Seatfrog logo"> -->
                <a href="https://seatfrog.com?ref=secretfare" alt="Seatfrog website - opens in new tab" target="_blank" rel=”noopener”>
                    <img class="footer-component__logo-section--seatfrog" routerLink="/" src="assets/images/Seatfrog-logo.svg" alt="">
                </a>
                <img class="footer-component__logo-section--co-brand"  src="assets/images/co-brand.svg" alt="cd brand logo">
                <div class="footer-component__logo-section--brands">
                    <img src="assets/images/TPE-Logo.svg" alt="transpennine express logo">
                    <img src="assets/images/EMR-Logo.svg" alt="east midlands railway logo">
                    <img src="assets/images/SWR-Logo.svg" alt="south western railway logo">
                </div>
            </div>
            <div class="footer-component__links-section">
                <div class="footer-component__links-section--group">
                    <div class="footer-component__links-section--title">Download Seatfrog</div>
                    <ul class="footer-component__links-section__links-list">
                        <a class="footer-component__links-section__links-list__element" href="https://apps.apple.com/app/seatfrog/id1129001637" target="_blank">
                            <img src="assets/images/apple-button.svg" alt="">
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=au.com.seatfrog.icarus&pli=1" target="_blank">
                            <img src="assets/images/google-button.svg" alt="">
                        </a>
                    </ul>
                </div>
                <div class="footer-component__links-section--group">
                    <div class="footer-component__links-section--title">About</div>
                    <ul class="footer-component__links-section__links-list">
                        <ng-container *ngFor="let element of aboutElementsList">
                            <ng-container *ngIf="element?.isExternal; else routerLinkTemplate">
                                <li>
                                    <a [attr.href]="element.linkTree[0]" target="_blank">{{element.text}}</a>
                                </li>
                            </ng-container>
                            <ng-template #routerLinkTemplate>
                                <li>
                                    <a [routerLink]="element.linkTree[0]">{{element.text}}</a>
                                </li>
                            </ng-template>
                        </ng-container>
                    </ul>
                </div>
                <div class="footer-component__links-section--group">
                    <div class="footer-component__links-section--title">How it works</div>
                    <ul class="footer-component__links-section__links-list">
                        <ng-container *ngFor="let element of howItWorksElementsList">
                            <ng-container *ngIf="element?.isExternal; else routerLinkTemplate">
                                <li>
                                    <a [attr.href]="element.linkTree[0]" target="_blank">{{element.text}}</a>
                                </li>
                            </ng-container>
                            <ng-template #routerLinkTemplate>
                                <li>
                                    <a [routerLink]="element.linkTree[0]">{{element.text}}</a>
                                </li>
                            </ng-template>
                        </ng-container>
                    </ul>
                </div>
                <div class="footer-component__links-section--group">
                    <div class="footer-component__links-section--title">Connect</div>
                    <ul class="footer-component__links-section__links-list">
                        <ng-container *ngFor="let element of connectElementsList">
                            <ng-container *ngIf="element?.isExternal; else routerLinkTemplate">
                                <li>
                                    <a [attr.href]="element.linkTree[0]" target="_blank">{{element.text}}</a>
                                </li>
                            </ng-container>
                            <ng-template #routerLinkTemplate>
                                <li>
                                    <a [routerLink]="element.linkTree[0]">{{element.text}}</a>
                                </li>
                            </ng-template>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-component__bottom-section">
            <div class="footer-component__rights">
                <div class="footer-component__rights--text">© {{ currentYear }} Seatfrog. All Rights Reserved. <a href="https://seatfrog.com/privacy/?ref=secretfare">Privacy</a> | <a href="https://seatfrog.com/terms/?ref=secretfare">Terms</a></div>
                <div class="footer-component__rights--logo">
                    <img src="assets/images/Travolution-Awards-Logo.svg" alt="Travolution Awards Logo">
                    <img src="assets/images/the-europas.svg" alt="Europas Logo">
                </div>
            </div>
        </div>
    </div>
</footer>

