import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _minDaysInFuture = 2;
  private _maxDaysInFuture = 90;

  private _minBookableIsoDate = this.getDateInFuture(this._minDaysInFuture);
  private _maxBookableIsoDate = this.getDateInFuture(this._maxDaysInFuture);

  tocNameMapping: Record<string, string> = {
    "TP": "TransPennine Express",
    "VT": "Avanti West Coast",
    "EM": "East Midlands Railway",
    "SW": "South Western Railway",
  }; // TODO: Move to environment?

  get minDaysInFuture(): number {
    return this._minDaysInFuture;
  }

  get maxDaysInFuture(): number {
    return this._maxDaysInFuture;
  }

  get minBookableDate(): Date {
    return new Date(this._minBookableIsoDate);
  }

  get maxBookableDate(): Date {
    return new Date(this._maxBookableIsoDate);
  }

  private getDateInFuture(daysToAdd: number): string {
    const newDate = new Date(Date.now() + daysToAdd * 24 * 60 * 60 * 1000);
    newDate.setHours(0, 0, 0);

    return newDate.toISOString();
  }
}
