<div class="assistance-dialog">
    <div class="assistance-dialog__close">
        <app-svg-icon
            icon="close"
            size="medium"
            (click)="close()">
        </app-svg-icon>
    </div>
    <h3 class="assistance-dialog__title">
        WHEELCHAIR SPACE RESERVATIONS
    </h3>
    <span class="separator"></span>
    <p class="assistance-dialog__desc">
      Due to the last-minute nature of the Secret Fare platform, we cannot guarantee the availability of wheelchair spaces onboard coaches. Travellers who require a wheelchair space, or have restricted mobility should contact TransPennine Express, East Midlands Railway, or South Western Railway directly to check availability prior to purchasing a Secret Fare.
    </p>
    <div class="assistance-dialog__infobox">
        <app-svg-icon icon="white-info" size="large"></app-svg-icon>
        <p>
          Passengers with assisted travel requirements or those that require wheelchair space reservations can contact <a target="_blank" href="https://www.tpexpress.co.uk/help/passenger-assist">TransPennine Express here</a>, <a target="_blank" href="https://www.eastmidlandsrailway.co.uk/trains-stations/assisted-travel">East Midlands Railway here</a>, or <a target="_blank" href="https://www.southwesternrailway.com/travelling-with-us/assisted-travel">South Western Railway here</a>
        </p>
    </div>
    <button
        type="submit"
        class="assistance-dialog__submit"
        (click)="submit()">
        <p>I understand</p>
    </button>
</div>
